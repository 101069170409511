import React, { Fragment, useState, useEffect } from "react"
import { Link } from "gatsby"

import PlaygroundLayout from "../../../components/playgroundLayout"
import SEO from "../../../components/seo"

const START = 41;

let intervals = [0, 4, 7, 11, 12, 11, 7, 4];
const sequence =  intervals.map(x => x + START);

const NOTE_ON = 0x90;
const NOTE_OFF = 0x80;

const NOTE_DURATION = 300;

let currentSequenceId = -1;
let isPlaying = false;


const playNote = function(output) {
  if (currentSequenceId >= 0) {
    output.send([NOTE_OFF, sequence[currentSequenceId], 0x7f]);
  }

  if (!isPlaying) {
    return;
  }

  currentSequenceId++;
  if (currentSequenceId >= sequence.length) {
    currentSequenceId = 0;
  }
  output.send([NOTE_ON, sequence[currentSequenceId], 0x7f]);
  console.log("NOTE ON", sequence[currentSequenceId]);

  setTimeout(() => playNote(output), NOTE_DURATION);
}


const startSendingTheme = (output) => {
  if (!isPlaying) {
    isPlaying = true;
    playNote(output);
  } else {
    isPlaying = false;
  }
}

const getOutputs = () => {
  return navigator.requestMIDIAccess()
  .then(function(midiAccess) {
    const outputs = Array.from(midiAccess.outputs.values());
    console.log("Outputs", outputs);
    return outputs;
  });
}

const useMidiOutputs = () => {
  const [outputs, setOutputs] = useState([]);
  useEffect(() => {
    getOutputs().then(outputs => {
      setOutputs(outputs);
    });
  }, []);
  return outputs;
}

const OutputsListComponent = ({ onSelect }) => {
  const outputs = useMidiOutputs();

  return (
    <ul>{outputs.map(output => (
      <li key={output.id}><button onClick={() => onSelect(output) }>{output.name}</button></li>
    ))}
    </ul>

  )
}

const PlayGroundTest = () => (
  <PlaygroundLayout>
    <SEO title="MIDI Output" />
    <h1>Playground: MIDI Output</h1>
    <OutputsListComponent onSelect={startSendingTheme} />

  </PlaygroundLayout>
)

export default PlayGroundTest
